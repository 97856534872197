* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

@font-face {
   font-family: Dzeragir;
   src: url("./fonts/SHK_Dzeragir.otf");
}

@font-face {
   font-family: Dzeragir2;
   src: url("./fonts/ArmAllegroU.ttf");
}

@font-face {
   font-family: main;
   src: url("./fonts/Montserratarm-Regular.otf");
}

body {
   color: #232323;
   font-family: "main";
   background-color: white !important;
}

.hi-page {
   position: relative;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   overflow: hidden;

   & > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      filter: brightness(0.4);
      object-fit: cover;

      @media (max-width: 768px) {
         scale: 1.7;
         transform: translate(14px, -80px);
      }
   }

   h1 {
      text-align: center;
      font-size: 26px;
      letter-spacing: 6px;
      line-height: 36px;
      color: white;

      span {
         color: #b8b8b8;
      }
   }

   & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 60px;
      font-family: "Dzeragir";

      & > p {
         font-size: 38px;
         color: white;
         letter-spacing: 6px;
      }

      button {
         padding: 20px 40px;
         border: 2px solid white;
         background: none;
         color: white;
         margin-top: 60px;
         font-size: 26px;
         outline: none;
         letter-spacing: 3px;
         cursor: pointer;

         &:hover {
            background: #84848482;
         }
      }
   }
}

.invite-page {
   &-header {
      position: relative;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;

      // & > video {
      //    background-color: #313131;
      //    position: absolute;
      //    left: 0;
      //    top: 0;
      //    width: 100%;
      //    height: 100%;
      //    z-index: -1;
      //    filter: brightness(0.4);
      //    object-fit: cover;

      //    @media (max-width: 768px) {
      //       scale: 1.3;
      //       // transform: translate(14px, -80px);
      //    }
      // }

      &-img {
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         z-index: -1;
         filter: brightness(0.4);
         object-fit: cover;
      }

      h1 {
         text-align: center;
         font-size: 26px;
         font-size: 120px;
         letter-spacing: 6px;
         line-height: 145px;
         font-weight: 100;
         color: white;
         font-family: "Dzeragir2";

         @media (max-width: 768px) {
            font-size: 90px;
            line-height: 126px;
         }

         span {
            color: #b8b8b8;
         }

         & img {
            width: 135px;
            @media (max-width: 768px) {
               width: 93px;
            }
         }
      }

      & > div {
         display: flex;
         align-items: center;
         flex-direction: column;
         margin-top: 60px;
         font-family: "Dzeragir";

         & > p {
            font-size: 38px;
            color: white;
            letter-spacing: 6px;
         }
      }
   }

   &-calendar {
      max-width: 375px;
      margin: 120px auto 0;
      display: flex;
      justify-content: center;
      padding: 0 24px;
      flex-direction: column;

      h3 {
         font-family: "Dzeragir";
         text-align: center;
         margin-bottom: 48px;
         font-size: 40px;
         text-align: center;
      }

      p {
         text-align: center;
         font-size: 18px;
         margin-bottom: 48px;
      }

      img {
         width: 100%;
      }

      div {
         position: relative;

         .love {
            position: absolute;
            left: -13px;
            bottom: -9px;
            width: 47px;
            animation: pulse 1s infinite;

            @media (max-width: 370px) {
               width: 44px;
            }
         }
      }
   }

   &-photos {
      display: flex;
      max-width: 700px;
      margin: 120px auto;
      justify-content: center;
      gap: 10px;
      align-items: flex-start;
      padding: 0 24px;

      &-one {
         object-position: center;
      }

      &-two {
         object-position: left;
      }

      &-three {
         object-position: right;
      }

      img {
         height: auto;
         object-fit: cover;
      }

      & > img {
         height: 610px;
         width: 50%;
      }

      div {
         display: flex;
         width: 50%;
         flex-direction: column;
         gap: 10px;

         img {
            height: 300px;
         }
      }
   }

   &-timing {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      // max-width: 400px;
      width: 100%;
      margin: 0 auto;
      padding: 24px 16px;
      position: relative;

      &-bg {
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         z-index: -1;
         filter: brightness(0.3);
         object-fit: cover;
      }

      h3 {
         font-family: "Dzeragir";
         text-align: center;
         margin-bottom: 48px;
         font-size: 40px;
         color: white;
      }

      div {
         max-width: 400px;
         margin: 0 auto;
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-direction: column;
         width: 100%;

         &:not(:last-child) {
            border-bottom: 1px solid #ffffff5c;
            padding-bottom: 24px;
            margin-bottom: 24px;
         }

         h5 {
            font-size: 26px;
            min-width: 60px;
            font-weight: bold;
            color: white;
            margin-bottom: 16px;
         }

         p {
            font-size: 18px;
            width: auto;
            color: white;
            // width: 183px;
            text-align: center;
         }

         img {
            // margin-left: 40px;
            width: 60px;
            margin-bottom: 16px;
         }
      }
   }

   &-locations {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      max-width: 400px;
      width: 100%;
      margin: 120px auto 0;
      padding: 0 24px;

      h3 {
         font-family: "Dzeragir";
         text-align: center;
         margin-bottom: 48px;
         font-size: 40px;
      }

      &-bg {
         width: 100%;
      }

      &-icon {
         width: 55px;
         margin-bottom: 48px;
      }

      & > div:not(:last-child) {
         margin-bottom: 100px;
      }

      & > div {
         display: flex;
         flex-direction: column;
         align-items: center;

         p {
            text-align: center;
            color: #232323;
            font-size: 20px;
            margin-bottom: 48px;
         }

         button {
            background-color: #232323;
            border-radius: 30px;
            font-size: 22px;
            color: white;
            padding: 20px 0;
            width: 250px;
            margin-top: 48px;
            cursor: pointer;
            outline: none;
            border: none;

            &:hover {
               background-color: #3a3a3a;
            }
         }
      }
   }

   &-timer {
      min-width: 320px;
      max-width: 400px;
      width: 100%;
      margin: 120px auto;
      padding: 0 24px;

      h3 {
         font-family: "Dzeragir";
         text-align: center;
         margin-bottom: 48px;
         font-size: 40px;
      }
   }

   &-telegram {
      max-width: 450px;
      margin: 120px auto;
      display: flex;
      justify-content: center;
      padding: 0 24px;
      flex-direction: column;
      align-items: center;

      img {
         width: 80px;
         margin-bottom: 48px;
      }

      h3 {
         font-family: "Dzeragir";
         text-align: center;
         margin-bottom: 48px;
         font-size: 40px;
         text-align: center;
      }

      p {
         text-align: center;
         font-size: 18px;
         margin-bottom: 48px;
      }

      button {
         background-color: #232323;
         border-radius: 30px;
         font-size: 22px;
         color: white;
         padding: 20px 0;
         width: 250px;
         cursor: pointer;
         outline: none;
         border: none;

         &:hover {
            background-color: #3a3a3a;
         }
      }
   }

   &-form {
      max-width: 450px;
      margin: 120px auto;
      display: flex;
      justify-content: center;
      padding: 0 24px;
      flex-direction: column;
      align-items: center;

      h3 {
         font-family: "Dzeragir";
         text-align: center;
         margin-bottom: 48px;
         font-size: 40px;
         text-align: center;
      }

      & > p {
         text-align: center;
         font-size: 18px;
         margin-bottom: 12px;
      }

      &-wrapper {
         p {
            margin: 35px 0 10px;
         }

         &-item1 {
            div {
               margin-bottom: 12px;
            }
            input {
               width: 100%;
               border: none;
               border-bottom: 1px solid #232323;
               outline: none;
            }
         }

         &-item2 {
            div {
               margin-bottom: 12px;
               label {
                  margin-left: 8px;
               }
            }
         }
      }

      button {
         background-color: #232323;
         border-radius: 30px;
         font-size: 22px;
         color: white;
         padding: 20px 0;
         width: 250px;
         cursor: pointer;
         outline: none;
         border: none;
         margin-top: 48px;

         &:hover {
            background-color: #3a3a3a;
         }
      }
   }
}

@keyframes pulse {
   0% {
      transform: scale(1);
   }

   70% {
      transform: scale(1.2);
   }

   100% {
      transform: scale(1);
   }
}
